@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: garetRegular;
  src: url("./content/font/Garet-Regular.ttf");
}

@font-face {
  font-family: garetBold;
  src: url("./content/font/Garet-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: garetRegular, garetBold, sans-serif;
}

:root {
  --primary: #5d5fef;
  --white: #f4f4f4;
  --green: #7ed957;
  --red: #ff5d5d;
  --darkGray: #252525;
  --gray: #8a8a8a;
}

.dark-mode {
  --white: #000;
  --black: #fff;
}

body {
  background-color: #eee;
}

body.dark-mode {
  background-color: #252525;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
