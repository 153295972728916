.input-phone-number {
    input, button {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }
}

.input-phone-number {
    input{
        width: 100%;
    }
}