//need for this styles since it is not possive to style leaflet map directly using tailwind
.garageMap .leaflet-container {
  width: 80%;
  height: 25rem;
  border-radius: 30px;
  border: 1px solid black;
  z-index: 0; //important not to break other components
}
@media screen and (max-width: 1280px) {
  .garageMap .leaflet-container {
    width: 100%;
    height: 30vh;
    max-height: 30rem;
    min-height: 20rem;
  }
  
}
