//need for this styles since it is not possive to style leaflet map directly using tailwind
.previewMap .leaflet-container {
    width: 18rem;
    height: 85%;
    border-radius: 30px;
    border: 1px solid black;
    min-height: 14rem;
    z-index: 0; //important not to break other components
  }
  @media screen and (max-width: 1536px) { //2xl in tailwind
    .previewMap .leaflet-container {
      width: 25rem;
    }
    
  }
  @media screen and (max-width: 1300px) { //2xl in tailwind
    .previewMap .leaflet-container {
      width: 18rem;
    }
    
  }
  @media screen and (max-width: 1200px) {
    .previewMap .leaflet-container {
      width: 100%;
      height: 14rem;
      max-height: 30rem;
      min-height: 20rem;
    }
    
  }